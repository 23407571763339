import React from 'react'

export default function Feature(props) {
  return (
    <div>
        <div className=" col-md-3 col-lg-3 col-md-6 col-xs-12 mb-3  text-dark">
                  <div className="plan ng-star-inserted py-5  px-2 mb-5 shadow ">
                        <h2 className="fw-bolder heading-color text-center heading-text">{props.title} Data</h2>
                        <p className="text-center text-gray">Minimum 10 Sim Cards</p>
                        <div className="shadow-line mx-auto my-3"></div>
                        
                        <div className='fw-bold py-2'>
                            
                            <div className='text-center original-price'> 
                                <span className="text-green  heading-text my-2 mx-auto">£{props.price}<small className='mr-3 text-gray regular-text '><span className="dot_price text-green"></span>  Monthly</small></span>
                            </div>
                        </div>
                        <div className="text-gray text-center mt-2">
                        {props.features}
                        </div>
                        
                  </div>
        </div>
    </div>
  )
}
