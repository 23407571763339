import React from 'react'
import Feature from './Feature'

export default function HomePlan() {
  return (
    <div className="container sim_plan py-5" id='home-plan'>
            <div className="text-center py-5">
                    <h2 className="heading-text text-center fw-bolder">24 Month SIM Only Plans</h2>
                    <p>Available to new and upgrading customers</p>
            </div>
            

            <Feature title="3GB" oldPrice="10.75" price="10.75" 
                    features={
                            <>
                            <p>Unlimited Minutes</p>
                            <p>Unlimited Texts</p>
                            <p>3GB Data</p>
                            <p>5G Ready at no extra cost</p>
                            <p>Personal Hotspot</p>
                            <p>Go Roam Around the World</p>
                            </>
                    }  />

            <Feature title="10GB" oldPrice="14.62" price="14.62" 
                    features={
                            <>
                            <p>Unlimited Minutes</p>
                            <p>Unlimited Texts</p>
                            <p>3GB Data</p>
                            <p>5G Ready at no extra cost</p>
                            <p>Personal Hotspot</p>
                            <p>Go Roam Around the World</p>
                            </>
                    }  />
            
            <Feature title="30GB" oldPrice="19.95" price="19.95" 
                    features={
                            <>
                            <p>Unlimited Minutes</p>
                            <p>Unlimited Texts</p>
                            <p>3GB Data</p>
                            <p>5G Ready at no extra cost</p>
                            <p>Personal Hotspot</p>
                            <p>Go Roam Around the World</p>
                            </>
                    }  />
            <Feature title="Unlimited" oldPrice="23.75" price="23.75" 
            features={
                    <>
                    <p>Unlimited Minutes</p>
                    <p>Unlimited Texts</p>
                    <p>3GB Data</p>
                    <p>5G Ready at no extra cost</p>
                    <p>Personal Hotspot</p>
                    <p>Go Roam Around the World</p>
                    </>
            }  />
    </div>
  )
}
