import React from 'react'
import { useState , useEffect} from 'react';
  
export default function Plan(props) {
    const [state, setState] = useState("10");
    const [showCommission, setCommission] = useState("00.00");
    
    
      useEffect(() => {
        let simAmount = props.price;
        let noOfSims = state;
        let commission = 0.15;
        let totalCommission = (simAmount * noOfSims) * commission;
        setCommission(totalCommission.toFixed(2));
     });
    const handleChange = (event) => {
        setState(event.target.value, 10);
        
      }

     
      
  return (
    <div>
        <div className=" col-md-3 col-lg-3 col-md-6 col-xs-12 mb-3  text-dark">
                  <div className="plan ng-star-inserted py-5  px-2 mb-5 shadow ">
                        <h2 className="fw-bolder heading-color text-center heading-text">{props.title} Data</h2>
                        <p className="text-center text-gray">Minimum 10 Sim Cards</p>
                        <div className="shadow-line mx-auto my-3"></div>
                        
                        <div className='fw-bold py-2'>
                            <div>
                                <p className='h2 text-center old-price fw-bolder text-gray'>£{props.oldPrice}</p>
                            </div>    
                            <div className='text-center original-price'> 
                                <span className="text-green  heading-text my-2 mx-auto">£{props.price}<small className='mr-3 text-gray regular-text '><span className="dot_price text-green"></span>  Monthly</small></span>
                            </div>
                        </div>
                        <div className="text-gray text-center mt-2">
                        {props.features}
                        </div>
                        <div className='p-3 input-box position-relative'>
                            
                            <span className='text-ligh-gray h4 label'>Number of SIMS</span>
                            <input
                                className='form-control p-3'
                                type="tel"
                                id="number_of_sim"
                                placeholder="Enter Number"
                                text="Must be a number"
                                aria-describedby="exampleFormControlInputHelpInline"
                                onChange={(e) => handleChange(e)}
                                value={state}
                                name="{props.fieldName}"
                            />
                            
                        </div>
                        <div className="text-gray text-center p-3 fw-normal mt-2">
                            <p className='small-text text-left'>You will receive <b>£{showCommission}</b> per month for the life of the SIM.</p>
                        </div>
                  </div>
        </div>
    </div>
  )
}
