import React from 'react'
import Plan from '../Plan'

export default function SecretPlan() {
  return (
    <div className="container sim_plan py-5">
            <div className="text-center py-5">
                    <h2 className="heading-text text-center fw-bolder">24 Month SIM Only Plans</h2>
                    <p>Available to new and upgrading customers</p>
            </div>
            

            <Plan title="3GB" oldPrice="10.75" price="9.67" 
                    features={
                            <>
                            <p>Unlimited Minutes</p>
                            <p>Unlimited Texts</p>
                            <p>3GB Data</p>
                            <p>5G Ready at no extra cost</p>
                            <p>Personal Hotspot</p>
                            <p>Go Roam Around the World</p>
                            </>
                    }  fieldName="sim_number"  />

            <Plan title="10GB" oldPrice="14.62" price="13.16" 
                    features={
                            <>
                            <p>Unlimited Minutes</p>
                            <p>Unlimited Texts</p>
                            <p>3GB Data</p>
                            <p>5G Ready at no extra cost</p>
                            <p>Personal Hotspot</p>
                            <p>Go Roam Around the World</p>
                            </>
                    }  fieldName="sim_number" />
            
            <Plan title="30GB" oldPrice="19.95" price="17.98" 
                    features={
                            <>
                            <p>Unlimited Minutes</p>
                            <p>Unlimited Texts</p>
                            <p>3GB Data</p>
                            <p>5G Ready at no extra cost</p>
                            <p>Personal Hotspot</p>
                            <p>Go Roam Around the World</p>
                            </>
                    }  fieldName="sim_number" />
            <Plan title="Unlimited" oldPrice="23.75" price="21.37" 
            features={
                    <>
                    <p>Unlimited Minutes</p>
                    <p>Unlimited Texts</p>
                    <p>3GB Data</p>
                    <p>5G Ready at no extra cost</p>
                    <p>Personal Hotspot</p>
                    <p>Go Roam Around the World</p>
                    </>
            }  fieldName="sim_number" />
    </div>
  )
}
