import React from 'react'
import HomePlan from './plans/HomePlan';
import SecretPlan from './plans/SecretPlan';


export default function SimPlans(props) {
        const page = props.routePage;
        if (page == "home") {
                return (
                        <HomePlan />
                )
        }
        return <SecretPlan />
}
