
import { Link } from 'react-router-dom'



export default function navbar(props) {
const handlerModalStatus = (result) =>{
  // console.log(result)
  props.setModal(result)
}
const openMenu = (result) => {
  props.setMenu(result);
  
  
}

  return (
    <header>
      <div className='container' ref={navbar}>
        <div className='d-flex justify-content-between  py-4'>
          <div className='mobile_view d-flex align-items-center justify-content-between '>
            <Link className="brand-logo d-flex align-items-center" to="/">
              <img src="/images/logo.png" alt="site_logo" />
            </Link>
            <button className="navbar-toggler" onClick={() => openMenu("active")}  type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <img src='/images/menu.png' width={24} />
            </button>
          </div>
          <div>
            <ul className={`nav-menu  text-light ${props.showNavMenu}`}> 
              <div className='mobile-icon bg-theme d-flex justify-content-between align-items-center'>
                <li className='logo'>
                    <img src="/images/logo.png" alt="site_logo"/>
                </li>
                <li className="nav-item navbar-toggler" onClick={() => openMenu("inactive")}  type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <img src='/images/close.png' width={18} />
                </li>
              </div> 
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/credit-builder-plus">{props.menu1}</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/terms-and-conditions">{props.menu2}</Link>
              </li>
              
              
              <li className="nav-item">
                <span href="#" >
                    <img className='top-secret-img' onClick={() => handlerModalStatus(true)} src="/images/top-secret-new.png" width="140px" />
                </span> 
              </li>
            </ul>
          </div>
        </div>
      </div>
            </header>
  )
}
