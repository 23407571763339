import React from 'react'

import { Link } from 'react-router-dom'

export default function Footer(props) {
    const handlerModalStatus = (result) =>{
        // console.log(result)
        props.setModal(result)
      }
  return (
    <footer>
        <div className="container py-5">
            <div className="d-flex justify-content-center flex-column">
            
                <div className="text-center">
                    <img src="images/logo-inverted.png" alt="logo" width="200px" />
                </div>

                <ul className="nav-menu my-4 mb-lg-0 fw-bold text-gray d-flex py-4">
                    <li className="nav-item">
                        <Link className="nav-link" aria-current="page" to="/credit-builder-plus">{props.menu1}</Link>
                    </li>
                    <li className="nav-item ">
                        <Link className="nav-link" to="/terms-and-conditions">{props.menu2}</Link>
                    </li>
                    
                    
                    <li className="nav-item text-center">
                        <span href="#" >
                            <img className='top-secret-img' onClick={() => handlerModalStatus(true)} src="/images/top-secret-new.png" width="140px" />
                        </span> 
                    </li>
                </ul>
                
                <div className="social-icon text-center my-4">
                    <p className="stay-connected text-gray"> Stay connected... </p>
                    <a href="https://www.instagram.com/boshhhuk/">
                        <img alt="Instagram" src="/images/social-instagram.png" width="50px" className="mx-1" />
                    </a>
                    <a href="https://www.facebook.com/people/Boshhh/100087822891371/">
                        <img alt="Facebook" src="/images/social-facebook.png" width="50px" className="mx-1" />
                    </a>
                    <a href="https://www.youtube.com/channel/UC5pybccpBc08dsejUZriaWA">
                        <img alt="YouTube" src="/images/social-youtube.png" width="50px" className="mx-1" />
                    </a>
                    <a href="https://twitter.com/boshhhuk">
                        <img alt="Twitter" src="/images/social-twitter.png" width="50px" className="mx-1" />
                    </a>
                    <a href="https://www.tiktok.com/@boshhhuk">
                        <img alt="TikTok" src="/images/social-tiktok.png" width="50px" className="mx-1" />
                    </a>
                </div>
                <div className="text-center text-gray my-3">
                    <p> © 2022 Boshhh Ltd. All rights reserved. 
                        <br /> Registered in England and Wales. No. 13188665. 
                        <br /> 140 Lee Lane, Horwich, Bolton, England, BL6 7AF. 
                        <br />
                        <a className="text-gray text-decor-none" href="mailto:hello@boshhh.com">hello@boshhh.com</a></p>
                </div>
                <p className='mb-5 text-center text-gray'>All prices subject to VAT</p>
            </div>
        </div>
    </footer>
  )
}
