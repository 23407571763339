import React from 'react';
import { useState, useEffect } from 'react';
import './scss/App.scss';

import Banner from './components/Banner';
import Navbar from './components/Navbar';
import Blogs from './components/Blogs';
import SimPlans from './components/SimPlans';
import Footer from './components/Footer';
import TextSection from './components/TextSection';
import Whatsapp from './components/Whatsapp';

import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
import { Col } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';

import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import CreditBuilder from './components/CreditBuilder';
import navbar from './components/Navbar';




function MyVerticallyCenteredModal(props) {
  const [secretPinErr, setSecretPinErr] = useState("");
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/top-secret`; 
    navigate(path);
  }
  const handleModalForm = (e) =>{
    e.preventDefault()
    const data = {
      secretPin: e.target.elements.secretPin.value,
    }
    console.log('FormData: ', data.secretPin)
    if(data.secretPin == 1066){
        props.onHide();
        routeChange();
        navbar.focus();
    }else{
      setSecretPinErr("Invalid Secret Pin.Please enter valid pin");
    }
  }
  return (
    <Modal
    
      {...props}
      size="lg"
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered >
      <ModalHeader closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Secret Pin
        </Modal.Title>
      </ModalHeader>
      <ModalBody className='py-5'>
        <Form onSubmit={(e) => handleModalForm(e)}>
        <Form.Group as={Col} md="12" controlId="validationCustomUsername">
          <Form.Label className='h4'>Top Secret Pin</Form.Label>
          <InputGroup hasValidation>
            
            <Form.Control
            className='w-100 my-3'
              name="secretPin"
              type="tel"
              pattern="\d*" inputmode="decimal" 
              placeholder="Enter Secret Pin here..."
              aria-describedby="inputGroupPrepend"
              required
            />
            <p className='text-danger fw-normal h5' variant="error">
              {secretPinErr}
            </p>
          </InputGroup>
        </Form.Group>
          
          <Button  type="submit"   className="w-100 btn">Submit</Button>  
        </Form>
      </ModalBody>
      <ModalFooter>
        
        

      </ModalFooter>
    </Modal>
  );
}

function App() {
  const [modalShow, setModalShow] = useState(false);
  const [showNavMenu, setNavMenu] = useState('');

  let menu1 = "Credit Builder Plus";
  let menu2 = "Terms & Conditions";
  
  let location = useLocation();
  React.useEffect(() => {

    setNavMenu('inactive');  
  }, [location]);
  
  const setModal = (status) => {
    setModalShow(status);
    
  };
  const scrollToPlan = () => {
    const section = document.querySelector( '#home-plan' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };
  
  const setMenu = (navstatus) =>{
    console.log(navstatus);
    setNavMenu(navstatus);
    
  }
  return (
    <>
      <div className="content">
     
        <Navbar setMenu={setMenu} setModal={setModal} showNavMenu={showNavMenu} modelShow={modalShow} menu1={menu1} menu2={menu2} topSecret=""/>
        
        <Routes> 
            <Route exact path="/" element={
               <><Banner scrollToPlan={scrollToPlan}/><Blogs /><SimPlans routePage="home" /><TextSection /></>
              }
            />
            <Route exact path="/top-secret" element={
               <SimPlans routePage="top-secret"/>
            }
            />
            <Route exact path="/credit-builder-plus" element={
              <CreditBuilder />
            }
            />
            <Route exact path="/terms-and-conditions" element={
              <div className='container'>
                <h1>Terms and Conditions Page</h1>
               </div>
            }
            />
          </Routes>
          
        
        <Footer menu1={menu1} menu2={menu2} />
        <Whatsapp />
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </div>
    </>
  );
}

export default App;
   