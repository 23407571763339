import React from 'react'

export default function Banner(props) {
  return (
    <section className="hero-section p-4">
        <div className="container">
            <div className="row">
                <div className="col-md-6 col-lg-6 col-sm-12  d-flex align-items-center">
                
                    <div className="mb-3">
                        {/* <img className="my-5" src="images/bosh-business-logo.png" alt="site_logo" width="270px" /> */}
                        <p className="regular-tex">Do you require additional SIM cards for your business or just for yourself?  All of our business SIM plans come with unlimited UK texts and minutes, with offers ranging from as little as 3GB per month to unlimited data. Find the finest business SIM Only deals with boshhh for you below, and we'll take care of the rest.</p>
                        <div className="button-container mt-5 " >
                            <button onClick={props.scrollToPlan} className="btn btn-light text-dark btn-hero bg-white px-5 py-3">View Plans</button>
                        </div>
                    </div>
                </div>
                <div className="mobile_img col-md-6 col-lg-6 col-sm-12 p-3 align-items-center d-flex justify-content-center">
                    <img src="images/phone.png"  alt="boshhh-business" width="300px"/>
                </div>
            </div>
        </div>
    </section>
  )
}
